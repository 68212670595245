<template>
  <div class="page">
    <div class="page-back">
      <div class="back-btn"
           @click="goBack">
        返回
      </div>
      <div class="title">
        车辆详情
      </div>
    </div>
    <div class="content-box section">
      <easy-card title="基本信息">
        <el-descriptions :column="2" border>
          <el-descriptions-item>
            <template slot="label"> 车牌号码</template>
            <Numplate :type="basicInfo.autoType">
              {{ basicInfo.autoPlateNumber }}
            </Numplate>
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 绑定用户</template>
            {{ basicInfo.bindingUserName }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 车牌颜色</template>
            {{ plateNumberColor[(basicInfo.autoType)] }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 品牌型号 </template>
            {{basicInfo.brandModel}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 车身颜色 </template>
            {{basicInfo.autoBodyColor}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> 车辆类型 </template>
            {{basicInfo.autoType}}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 车辆余额（元）</template>
            {{ (parseFloat(basicInfo.autoBalance)).toFixed(2) }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> ETC免密 </template>
            {{basicInfo.etcFreeSecret}}
          </el-descriptions-item> -->
          <el-descriptions-item>
            <template slot="label"> 注册时间</template>
            {{ basicInfo.registTime }}
          </el-descriptions-item>
          <el-descriptions-item>
            <template slot="label"> 绑定时间</template>
            {{ basicInfo.bindingTime }}
          </el-descriptions-item>
          <!-- <el-descriptions-item>
            <template slot="label"> 认证时间 </template>
            {{basicInfo.certificationTime}}
          </el-descriptions-item> -->
          <!-- <el-descriptions-item>
            <template slot="label"> </template>
          </el-descriptions-item> -->
        </el-descriptions>
      </easy-card>

      <!-- <easy-card title="保单信息">
        <el-table
          :data="insuranceInfo"
          border
          style="width: 100%"
          class="common-table"
          size="medium"
          v-loading="isLoading"
        >
          <el-table-column prop="nowPhone" label="保险公司"> </el-table-column>
          <el-table-column prop="nowPhone" label="到期日期"> </el-table-column>
          <el-table-column prop="nowPhone" label="维护时间"> </el-table-column>
        </el-table>
      </easy-card> -->

      <!-- <easy-card title="保养信息">
        <el-table
          :data="maintainInfo"
          border
          style="width: 100%"
          class="common-table"
          size="medium"
          v-loading="isLoading"
        >
          <el-table-column prop="nowPhone" label="保养时间"> </el-table-column>
          <el-table-column prop="nowPhone" label="保养里程"> </el-table-column>
          <el-table-column prop="nowPhone" label="保养项目"> </el-table-column>
          <el-table-column prop="nowPhone" label="维护时间"> </el-table-column>
        </el-table>
      </easy-card> -->

      <!-- <easy-card title="年检信息">
        <el-table
          :data="yearlyInspectionInfo"
          border
          style="width: 100%"
          class="common-table"
          size="medium"
          v-loading="isLoading"
        >
          <el-table-column prop="nowPhone" label="注册时间"> </el-table-column>
          <el-table-column prop="nowPhone" label="车辆类型"> </el-table-column>
          <el-table-column prop="nowPhone" label="维护时间"> </el-table-column>
        </el-table>
      </easy-card> -->

      <easy-card title="长租信息">
        <el-table
            v-loading="isLoading"
            :data="longLeaseInfo"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="订单编号" prop="leaseOrderNo" width="300">
          </el-table-column>
          <el-table-column label="结算方" prop="settlementName" width="280">
          </el-table-column>
          <el-table-column label="车场" prop="parkingLotName" width="290">
          </el-table-column>
          <el-table-column label="订单状态" prop="orderStatus" width="220">
          </el-table-column>
          <el-table-column label="手机号码" prop="userPhone" width="240">
          </el-table-column>
          <el-table-column label="车牌号" prop="autoPlateNumber" width="220">
          </el-table-column>
          <el-table-column label="开始时间" prop="beginTime" width="250">
          </el-table-column>
          <el-table-column label="结束时间" prop="endTime" width="250">
          </el-table-column>
        </el-table>
      </easy-card>

      <easy-card title="绑定记录">
        <el-table
            v-loading="isLoading"
            :data="bindingInfo"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="用户名" prop="userName"></el-table-column>
          <el-table-column label="手机号" prop="userPhone"></el-table-column>
          <el-table-column label="时间" prop="bindingTime"></el-table-column>
          <el-table-column label="绑定操作类型" prop="bindingType">
          </el-table-column>
        </el-table>

        <div class="tabs">
          <el-tabs v-model="currentTab" type="card">
            <el-tab-pane label="充值查询" name="recharge">
              <el-table
                  v-loading="isLoading"
                  :data="rechargeInfo"
                  border
                  class="common-table"
                  size="medium"
                  style="width: 100%"
              >
                <el-table-column label="充值单号" prop="orderNo" width="240">
                </el-table-column>
                <el-table-column label="用户名" prop="userName" width="220">
                </el-table-column>
                <el-table-column label="手机号" prop="userPhone" width="220">
                </el-table-column>
                <el-table-column label="车牌号" prop="autoPlateNumber" width="220">
                </el-table-column>
                <el-table-column label="充值方式" prop="rechargeMethod" width="220">
                </el-table-column>
                <el-table-column label="金额(元)" prop="rechargeAmount" width="220">
                </el-table-column>
                <el-table-column label="时间" prop="rechargeTime" width="220">
                </el-table-column>
                <el-table-column label="用户类型" prop="userType" width="220">
                </el-table-column>
                <el-table-column label="类型" prop="rechargeType" width="220">
                </el-table-column>
                <el-table-column label="原单信息" prop="originInfo" width="220">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="交易查询" name="trade">
              <el-table
                  v-loading="isLoading"
                  :data="tradeInfo"
                  border
                  class="common-table"
                  size="medium"
                  style="width: 100%"
              >
                <el-table-column label="交易单号" prop="tradeOrderNo" width="240">
                </el-table-column>
                <el-table-column label="交易日期" prop="tradeDate" width="220">
                </el-table-column>
                <el-table-column label="交易类型" prop="tradeType" width="220">
                </el-table-column>
                <el-table-column label="订单金额(元)" prop="orderAmount" width="220">
                </el-table-column>
                <el-table-column label="实收金额(元)" prop="receiveAmount" width="220">
                </el-table-column>
                <el-table-column label="优惠金额(元)" prop="couponAmount" width="220">
                </el-table-column>
                <el-table-column label="线下金额(元)" prop="offLineAmount" width="220">
                </el-table-column>
                <el-table-column label="订单关联编号" prop="orderRelationNo" width="220">
                </el-table-column>
              </el-table>
            </el-tab-pane>
            <el-tab-pane label="巡检缴费" name="pay">
              <el-table
                  v-loading="isLoading"
                  :data="inspectionPayInfo"
                  border
                  class="common-table"
                  size="medium"
                  style="width: 100%"
              >
                <el-table-column label="巡检信息" width="240">
                  <el-table-column label="巡检编号" prop="inspectorNo" width="200"></el-table-column>
                  <el-table-column label="巡检员" prop="inspectorName" width="200"></el-table-column>
                  <el-table-column label="手机号" prop="phone" width="200"></el-table-column>
                </el-table-column>
                <el-table-column label="车场信息" width="220">
                  <el-table-column label="地区" prop="region" width="200"></el-table-column>
                  <el-table-column label="收费时间" prop="chargingTime" width="200"></el-table-column>
                  <el-table-column label="停车场名称" prop="parkingLotName" width="200"></el-table-column>
                </el-table-column>
                <el-table-column label="缴费信息" width="220">
                  <el-table-column label="车牌号" prop="autoPlateNumber" width="200"></el-table-column>
                  <el-table-column label="巡检金额" prop="inspectAmount" width="200"></el-table-column>
                  <el-table-column label="缴费方式" prop="payMethod" width="200"></el-table-column>
                </el-table-column>
              </el-table>
            </el-tab-pane>
          </el-tabs>
        </div>
      </easy-card>

      <easy-card title="停车订单">
        <el-table
            v-loading="isLoading"
            :data="parkingOrderInfo"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="停车信息">
            <el-table-column label="订单号" prop="orderNo" width="100"></el-table-column>
            <el-table-column label="城区" prop="regionName" width="100"></el-table-column>
            <el-table-column label="车场" prop="parkingLotName" width="100"></el-table-column>
            <el-table-column label="泊位号" prop="parkingSpaceNo" width="100"></el-table-column>
            <el-table-column label="车牌号" prop="autoPlateNumber" width="100"></el-table-column>
            <el-table-column label="驶入时间" prop="driveInTime" width="100"></el-table-column>
            <el-table-column label="驶出时间" prop="driveOutTime" width="100"></el-table-column>
            <el-table-column label="停车时长" prop="parkingTime" width="100"></el-table-column>
            <el-table-column label="停车图片" prop="parkingImage" width="100"></el-table-column>
          </el-table-column>
          <el-table-column label="交易信息">
            <el-table-column label="订单金额(元)" prop="orderAmount" width="100">
            </el-table-column>
            <el-table-column label="冻结金额(元)" prop="freezeAmount" width="100">
            </el-table-column>
            <el-table-column label="交易金额(元)" prop="tradeAmount" width="100">
            </el-table-column>
            <el-table-column label="优惠金额(元)" prop="couponAmount" width="100">
            </el-table-column>
            <el-table-column label="抵扣额" prop="deductionAmount" width="100"></el-table-column>
            <el-table-column label="线下减免(元)" prop="offLineDeductionAmount" width="100">
            </el-table-column>
          </el-table-column>
          <el-table-column label="付款状态" prop="payStatus"></el-table-column>
        </el-table>
      </easy-card>

      <easy-card title="优惠券信息">
        <el-table
            v-loading="isLoading"
            :data="couponInfo"
            border
            class="common-table"
            size="medium"
            style="width: 100%"
        >
          <el-table-column label="优惠信息">
            <el-table-column label="优惠券编号" prop="couponNo" width="200"></el-table-column>
            <el-table-column label="触发事件" prop="eventName" width="200"></el-table-column>
            <el-table-column label="优惠券类型" prop="couponType" width="100"></el-table-column>
            <el-table-column label="优惠额度" prop="couponAmount" width="100"></el-table-column>
            <el-table-column label="有效日期始" prop="effectiveTimeStart" width="200"></el-table-column>
            <el-table-column label="有效日期止" prop="effectiveTimeEnd" width="200"></el-table-column>
            <el-table-column label="结算方" prop="settlementName" width="100"></el-table-column>
            <el-table-column label="使用限制" prop="useLimit" width="100"></el-table-column>
            <el-table-column label="发行编号" prop="publishNo" width="100"></el-table-column>
          </el-table-column>
          <el-table-column label="使用信息">
            <el-table-column label="使用时间" prop="usedTime" width="200"></el-table-column>
            <el-table-column label="使用区域" prop="usedRegion" width="100"></el-table-column>
            <el-table-column label="使用车场" prop="usedParkingLotName" width="100"></el-table-column>
            <el-table-column label="订单编号" prop="parkingOrderNo" width="200"></el-table-column>
            <el-table-column label="订单金额" prop="orderAmount" width="100"></el-table-column>
            <el-table-column label="订单类型" prop="orderType" width="100"></el-table-column>
          </el-table-column>
        </el-table>
      </easy-card>

      <easy-card title="停车记录">
        <div class="search-box section">
          <el-form
              ref="query"
              :inline="true"
              :model="query"
              class="demo-form-inline"
              size="medium"
          >
            <el-form-item prop="parkingLotId">
              <SuggestParking v-model="query.parkingLotId" style="width: 260px"/>
            </el-form-item>
            <el-form-item>
              <div class="xxd-input__wrap">
                <div>驶入时间：</div>
                <el-date-picker
                    v-model="driveInTime"
                    end-placeholder="结束时间"
                    range-separator="~"
                    start-placeholder="开始时间"
                    type="monthrange"
                    value-format="yyyy-MM"
                >
                </el-date-picker>
              </div>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="goSearch">搜索</el-button>
              <el-button @click="resetForm">重置</el-button>
            </el-form-item>
          </el-form>
        </div>
        <div class="content-box section footer">
          <div class="op-box">
            <div class="op-content">
              共计<span class="total-size">{{ total }}</span
            >条信息
            </div>
            <div class="op-extra">
<!--              <icon-button icon="share" title="导出" @click.native="exportData"/>-->
            </div>
          </div>
          <div>
            <el-table
                v-loading="isLoading"
                :data="parkingRecordInfo"
                border
                class="common-table"
                size="medium"
                style="width: 100%"
            >
              <el-table-column label="停车场名称" prop="parkingLotName" width="300">
              </el-table-column>
              <el-table-column
                  label="最后一次停车驶入时间"
                  prop="lastParkingDriveInTime"
                  width="240"
              >
              </el-table-column>
              <el-table-column
                  label="最后一次停车驶离时间"
                  prop="lastParkingDriveOutTime"
                  width="240"
              >
              </el-table-column>
              <el-table-column label="最后一次停车订单编号" prop="lastParkingOrderNo">
              </el-table-column>
              <el-table-column label="停车次数" prop="parkingCount" width="200">
              </el-table-column>
            </el-table>
          </div>
        </div>
      </easy-card>
    </div>

    <!-- 导出弹窗 -->
    <el-dialog
        :append-to-body="true"
        :visible.sync="exportDialogFormVisible"
        class="common-dialog"
        title="导出选项"
        width="600px"
    >
      <el-form ref="form" :model="form" class="label-weight">
        <el-form-item
            label="导出条数"
            label-width="120px"
            prop="exportSize"
        >
          <el-col :span="16">
            <el-input
                v-model="form.exportSize"
                placeholder="请填写导出条数"
            ></el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmExport"
        >确认导出
        </el-button
        >
      </div>
    </el-dialog>
  </div>
</template>


<script>
import SuggestParking from "@/components/SuggestParking";
import Numplate from "@/components/Numplate";
import {
  getCouponInfo,
  getLeaseInfo,
  getParkingOrderInfo,
  getVehicleInfo,
  getVehicleParkingRecords,
  getVehiclePatrolFeeInfo,
  getVehicleRechargeRecord,
  getVehicleTransaction,
  vehicleBindRecording
} from "@/api/vehicle";

export default {
  components: {
    SuggestParking,
    Numplate
  },
  data() {
    return {
      id: "",
      isLoading: false,
      basicInfo: {}, //基本信息
      // insuranceInfo: [], //保单信息
      // maintainInfo: [], //保养信息
      // yearlyInspectionInfo:[], //年检信息
      longLeaseInfo: [], //长租信息
      bindingInfo: [],//绑定记录
      rechargeInfo: [], //充值查询
      tradeInfo: [], //交易查询
      inspectionPayInfo: [], //巡检缴费
      parkingOrderInfo: [], //停车订单
      couponInfo: [], //优惠券信息
      parkingRecordInfo: [], //停车记录
      query: {
        parkingLotId: "",
        driveInTimeStart: "",
        driveInTimeEnd: "",
      },
      driveInTime: [],
      total: 0,
      currentTab: "recharge",
      exportDialogFormVisible: false, //导出弹窗
      form: {
        exportSize: 1,
      },
      plateNumberColor: {
        "01": "黄底黑字",
        "02": "蓝底白字",
        "03": "渐变绿色",
        "04": "黄绿渐变",
        "06": "黑底白字",
        "23": "白底黑字",
        "99": "其他",

        "黄色": "黄底黑字",
        "蓝色": "蓝底白字",
        "绿色": "渐变绿色",
        "黑色": "黑底白字",
        "白色": "白底黑字",
      },
    };
  },
  methods: {
    //返回车辆管理列表页
    goBack() {
      this.$router.back();
    },
    //查询基本信息
    getBasicInfo(id) {
      getVehicleInfo(id).then(res => {
        if (res.code === 30 && res.result) {
          this.basicInfo = res.returnObject;
        }
      })
    },
    //查询长租信息
    getLongLeaseInfo(id) {
      this.isLoading = true;
      getLeaseInfo(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.longLeaseInfo = res.returnObject;
        }
      })
    },
    //查询车辆绑定记录
    getBingdingInfo(id) {
      this.isLoading = true;
      vehicleBindRecording(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.bindingInfo = res.returnObject;
        }
      })
    },
    //查询车辆充值
    getRechargeInfo(id) {
      this.isLoading = true;
      getVehicleRechargeRecord(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.rechargeInfo = res.returnObject;
        }
      })
    },
    //交易查询信息
    getTradeInfo(id) {
      this.isLoading = true;
      getVehicleTransaction(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.tradeInfo = res.returnObject;
        }
      })
    },
    //巡检缴费信息
    getInspectionPayInfo(id) {
      this.isLoading = true;
      getVehiclePatrolFeeInfo(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.inspectionPayInfo = res.returnObject;
        }
      })
    },
    //查询停车订单信息
    getParkingOrderInfo(id) {
      this.isLoading = true;
      getParkingOrderInfo(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.parkingOrderInfo = res.returnObject;
        }
      })
    },
    //查询优惠券信息
    getCouponInfo(id) {
      this.isLoading = true;
      getCouponInfo(id).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.couponInfo = res.returnObject;
        }
      })
    },

    //查询停车记录
    goSearch() {
      this.isLoading = true;
      const vehicleId = this.id;
      const parkingLotId = this.query.parkingLotId;
      if (this.driveInTime.length > 0) {
        this.query.driveInTimeStart = this.driveInTime[0];
        this.query.driveInTimeEnd = this.driveInTime[1];
      }
      const driveInTimeStart = this.query.driveInTimeStart;
      const driveInTimeEnd = this.query.driveInTimeEnd;
      getVehicleParkingRecords(parkingLotId, driveInTimeStart, driveInTimeEnd, vehicleId).then(res => {
        this.isLoading = false;
        if (res.code === 30 && res.result) {
          this.parkingRecordInfo = res.returnObject;
        }
      })
    },
    //重置
    resetForm() {
      this.$refs.query.resetFields();
      this.driveInTime = [];
      this.goSearch();
    },
    //导出弹窗
    exportData() {
      this.exportDialogFormVisible = true;
    },
    //确认导出
    confirmExport() {
      const loading = this.$loading({
        lock: true,
        text: '正在导出',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0)'
      });
      // const params = this.paramFormat(this.query);
      // if(this.searchDate.length > 0) {
      //   params["startDate"] = this.searchDate[0];
      //   params["endDate"] = this.searchDate[1];
      // }
      // params["isExport"] = "1";
      // params["exportSize"] = this.form.exportSize;
      const res = getVehicleParkingRecords();
      if (res && res.code === 30 && res.result) {
        loading.close();
        this.exportDialogFormVisible = false;
      }
    }
  },
  mounted() {
    const id = this.$route.query.id;
    this.id = id;
    this.getBasicInfo(id);
    this.getLongLeaseInfo(id);
    this.getBingdingInfo(id);
    this.getRechargeInfo(id);
    this.getTradeInfo(id);
    this.getInspectionPayInfo(id);
    this.getParkingOrderInfo(id);
    this.getCouponInfo(id);
    this.goSearch();
  },
};
</script>


<style lang="less" scoped>
.tabs {
  margin-top: 20px;
}

.footer {
  margin-top: -30px;
}
</style>
